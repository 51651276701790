import App from "./App.vue";
import Antd from "ant-design-vue";
import Vue from "vue";
import VueRs from "@appbaseio/reactivesearch-vue";
import "ant-design-vue/dist/antd.css";

Vue.use(Antd);
Vue.use(VueRs);
new Vue({
  render: (h) => h(App),
}).$mount("#app");
