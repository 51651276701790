<template>
  <div
    :style="{
      'padding': '25px 0px',
      position: showSearchAs,
      top: '10px',
      'z-index': 1000,
      display: toggleFilters ? 'none' : 'block',
    }"
  >
    <reactive-component
      :preferencesPath="`pageSettings.pages.${pageSettings.currentPage}.componentSettings.search`"
      componentId="search"
      filterLabel="Search"
      class="search"
      :debounce="100"
      :placeholder="searchText"
      iconPosition="right"
      :icon="searchIcon"
      URLParams
      :css="searchStyles(theme.colors)"
      :popularSuggestionsConfig="{
        size: 3,
      }"
      :recentSuggestionsConfig="{
        size: 3,
      }"
      enableIndexSuggestions
      :indexSuggestionsConfig="{
        size: 3,
        valueFields,
      }"
      :size="6"
      showDistinctSuggestions
      v-bind="componentProps"
    />
  </div>
</template>
<script>
import { css } from "@emotion/css";
import get from "lodash.get";
import { getDataFieldValue } from "../utils";

const searchStyles = ({ titleColor }) => css`
  .section-header > h3 {
    margin: 8px 0;
    color: ${titleColor};
    font-size: 16px;
  }
`;

export default {
  name: "Search",
  data() {
    const searchIcon = get(this.searchSettings, "searchButton.icon", "");
    const searchText = get(
      this.searchSettings,
      "searchButton.text",
      "Search for products..."
    );
    let valueFields = ["term_s"];
    const defaultFields = get(this.resultSettings, "fields", {});
    if (defaultFields.title.dataField) {
      valueFields = [...valueFields, defaultFields.title.dataField];
    } else if (defaultFields.description.dataField) {
      valueFields = [...valueFields, defaultFields.description.dataField];
    }
    const componentProps = get(this.searchSettings, "rsConfig", {});
    return {
      valueFields,
      searchStyles,
      searchText,
      searchIcon,
      showSearchAs: get(this.searchSettings, "showSearchAs", "sticky"),
      componentProps: {
        ...componentProps,
        dataField: getDataFieldValue(
          get(this.resultSettings, "fields.title", "title")
        ),
      },
    };
  },
  props: {
    toggleFilters: {
      type: Boolean,
      default: false,
    },
    searchSettings: {
      type: Object,
      default() {
        return {};
      },
    },
    pageSettings: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: Object,
      default() {
        return {};
      },
    },
    resultSettings: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
