<template>
  <div :class="facetStyles(toggleFilters)">
    <div v-bind:key="filter._id" v-for="filter in filters" >
      <a-collapse :bordered="false" :activeKey="[filter]">
        <a-collapse-panel
          showArrow
          :key="filter"
          :style="{
            ...getFontFamily(),
            'max-width': isMobile ? 'none' : '298px',
            overflow: 'auto',
          }"
          class="filter"
          v-if="get(componentSettings[filter], 'enabled')"
        >
          <template #header>
            <span
              :style="{
                color: get(theme, 'colors.titleColor'),
                'font-weight': 'bold',
                'font-size': '15px',
                display: 'flex'
              }"
            >
              {{ get(componentSettings[filter], "rsConfig.title") }}
             
            </span>
          </template>
          <Facet
            :facet="componentSettings[filter]"
            :getFontFamily="getFontFamily"
            :theme="theme"
            :filter="filter"
            :pageSettings="pageSettings"
            :preferences="preferences"
          />
        </a-collapse-panel>
      </a-collapse>
    </div>
  </div>
</template>
<script>
import { css } from "@emotion/css";
import Facet from "./Facet.vue";
import get from "lodash.get";

const facetStyles = (toggled) => css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax (250px, 1fr));
  grid-gap: 0;
  align-self: start;
  border: 1px solid #eee;
  .label-container {
    display: flex;
    justify-content: space-between;
  }
  @media screen and (max-width: 600px) {
    display: ${toggled ? "grid" : "none"};
    grid-template-columns: 1fr;
  }
  box-shadow: 0;
`;

export default {
  name: "Filters",
  data() {
    return {
      filters: Object.keys(this.componentSettings).filter(
        (i) => i !== "search" && i !== "result"
      ),
      facetStyles,
      get
    };
  },
  props: {
    preferences: {
      type: Object,
      default() {
        return {};
      },
    },
    getFontFamily: {
      type: Function,
    },
    theme: {
      type: Object,
      default() {
        return {};
      },
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    toggleFilters: {
      type: Boolean,
      default: false,
    },
    componentSettings: {
      type: Object,
      default() {
        return {};
      },
    },
    pageSettings: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: { Facet },
};
</script>
