<template>
  <helmet-provider>
    <div id="app" :style="themeConfig">
      <helmet>
        <link
          href="https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.4/leaflet.css"
          rel="stylesheet"
        />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link
          :href="`https://fonts.googleapis.com/css2?family=${googleFont}&display=swap`"
          rel="stylesheet"
        />
      </helmet>
      <SearchPlugin />
    </div>
  </helmet-provider>
</template>
<script type="text/javascript" src="dist/vue-helmet.min.js"></script>
<script>
import { HelmetProvider, Helmet } from "@jnields/vue-helmet";
import SearchPlugin from "./components/SearchPlugin.vue";
import { getSearchPreferences } from "./utils";
import get from "lodash.get";

export default {
  name: "App",
  components: {
    SearchPlugin,
    HelmetProvider,
    Helmet,
  },
  data() {
    const preferences = getSearchPreferences();
    const { themeSettings } = preferences;
    const fontFamily = get(themeSettings, "rsConfig.typography.fontFamily", "");
    const fontWeight = get(themeSettings, "meta.fontWeight");
    const googleFontWeight = fontWeight ? `:wght@${fontWeight}` : "";
    const googleFont = fontFamily + googleFontWeight;
    const safeFontFamily = fontFamily
      ? `${fontFamily} sans-serif`
      : "sans-serif";

    return {
      safeFontFamily,
      fontWeight,
      googleFont,
      color: get(themeSettings, "rsConfig.colors.textColor", ""),
      backgroundColor: get(themeSettings, "meta.bodyBackgroundColor"),
    };
  },
  props: {
    id: { type: String, default: "reactivesearch-shopify" },
  },
  methods: {
    getPropsById(elemId) {
      const container = document.getElementById(elemId);
      if (container) {
        return {
          widgetId: container.getAttribute("widget-id"),
          currentProduct: container.getAttribute("current-product"),
          isOpen: container.getAttribute("isOpen") === "true",
          openAsPage: container.getAttribute("openaspage") === "true",
          isPreview: container.getAttribute("isPreview") === "true",
          disableSearchText:
            container.getAttribute("disableSearchText") === "true",
        };
      }
      return null;
    },
  },
  computed: {
    themeConfig() {
      return {
        "font-weight": this.fontWeight,
        "font-family": this.safeFontFamily,
        color: this.color,
        "background-color": this.backgroundColor,
      };
    },
  },
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
