const appbasePrefs = {
  "name": "Search good-books-ds + Vue Ecommerce App",
  "description": "",
  "pipeline": "good-books-ds",
  "backend": "elasticsearch",
  "id": "41243799-d543-4771-a76f-1133f6b0ee06",
  "pageSettings": {
    "currentPage": "home",
    "pages": {
      "home": {
        "componentSettings": {
          "search": {
            "componentType": "SEARCHBOX",
            "customMessages": {
              "noResults": "No suggestions found for <mark>[term]</mark>"
            },
            "searchButton": {
              "icon": "",
              "text": "Click here to search"
            },
            "redirectUrlText": "Open URL",
            "redirectUrlIcon": "",
            "showSearchAs": "sticky",
            "fields": {
              "title": {
                "dataField": "original_title",
                "highlight": true
              },
              "description": {
                "dataField": "authors",
                "highlight": true
              },
              "price": {
                "dataField": "average_rating",
                "highlight": false
              },
              "priceUnit": "Rating.",
              "image": {
                "dataField": "image",
                "highlight": false
              },
              "handle": {
                "dataField": "image-url",
                "highlight": false
              },
              "handleViewer": "link",
              "userDefinedFields": [],
              "cssSelector": ""
            },
            "rsConfig": {
              "autosuggest": true,
              "enablePopularSuggestions": false,
              "enableRecentSearches": false,
              "highlight": false,
              "showVoiceSearch": true,
              "componentType": "SEARCHBOX"
            }
          },
          "result": {
            "componentType": "REACTIVELIST",
            "fields": {
              "title": {
                "dataField": "original_title",
                "highlight": true
              },
              "description": {
                "dataField": "authors",
                "highlight": true
              },
              "price": {
                "dataField": "average_rating",
                "highlight": false
              },
              "priceUnit": "Rating.",
              "image": {
                "dataField": "image",
                "highlight": false
              },
              "handle": {
                "dataField": "image-url",
                "highlight": false
              },
              "handleViewer": "link",
              "userDefinedFields": [],
              "cssSelector": ""
            },
            "customMessages": {
              "resultStats": "[count] products found in [time] ms",
              "noResults": "No Results Found!"
            },
            "rsConfig": {
              "pagination": false,
              "infiniteScroll": true,
              "componentType": "REACTIVELIST"
            },
            "sortOptionSelector": [],
            "resultHighlight": true,
            "layout": "grid",
            "viewSwitcher": true,
            "displayFields": {}
          },
          "Authors_0": {
            "enabled": true,
            "customMessages": {
              "loading": "Fetching Options",
              "noResults": "No items Found"
            },
            "rsConfig": {
              "title": "Authors",
              "dataField": "authors.keyword",
              "filterLabel": "Authors",
              "filterType": "list",
              "size": 5,
              "queryFormat": "or",
              "sortBy": "count",
              "componentType": "MULTILIST",
              "showCount": true,
              "showCheckbox": true,
              "showSearch": false,
              "showMissing": false,
              "missingLabel": null,
              "selectAllLabel": null,
              "componentId": "Authors_0"
            },
            "componentType": "MULTILIST",
            "facetType": "dynamic"
          },
          "Books_Count_1": {
            "enabled": false,
            "customMessages": {
              "loading": "Fetching Options",
              "noResults": "No items Found"
            },
            "rsConfig": {
              "title": "Books Count",
              "dataField": "books_count",
              "filterLabel": "Books Count",
              "filterType": "range",
              "startValue": "10",
              "endValue": "100",
              "startLabel": "Start",
              "endLabel": "End",
              "showHistogram": true,
              "calendarInterval": null,
              "componentType": "RANGEINPUT",
              "componentId": "Books_Count_1"
            },
            "componentType": "RANGEINPUT",
            "facetType": "dynamic"
          }
        },
        "indexSettings": {
          "index": "good-books-ds",
          "fusionSettings": {
            "app": null,
            "profile": null,
            "searchProfile": null,
            "meta": {
              "sponsoredProfile": null
            }
          },
          "endpoint": {
            "url": "/good-books-ds/_reactivesearch",
            "method": "POST",
            "headers": "{\"Authorization\":\"Basic YTAzYTFjYjcxMzIxOjc1YjY2MDNkLTk0NTYtNGE1YS1hZjZiLWE0ODdiMzA5ZWI2MQ==\"}"
          }
        }
      }
    },
    "fields": {
      "cssSelector": "",
      "description": {
        "dataField": "authors",
        "highlight": false
      },
      "handle": {
        "dataField": "image-url",
        "highlight": false
      },
      "handleViewer": "link",
      "image": {
        "dataField": "",
        "highlight": false
      },
      "price": {
        "dataField": "average_rating",
        "highlight": false
      },
      "priceUnit": "Rating.",
      "title": {
        "dataField": "original_title",
        "highlight": false
      },
      "userDefinedFields": []
    }
  },
  "themeSettings": {
    "type": "vue-ecomm",
    "customCss": "",
    "rsConfig": {
      "colors": {
        "primaryColor": "#0B6AFF",
        "primaryTextColor": "#fff",
        "textColor": "#424242",
        "titleColor": "#424242"
      },
      "typography": {
        "fontFamily": "Open Sans"
      }
    },
    "meta": {
      "bodyBackgroundColor": "#fff",
      "navbarBackgroundColor": "#001628",
      "linkColor": "#3eb0ef",
      "fontWeight": 400
    }
  },
  "globalSettings": {
    "currency": "USD",
    "showSelectedFilters": true,
    "meta": {
      "branding": {
        "logoUrl": "",
        "logoWidth": 200,
        "logoAlignment": "left"
      },
      "deploySettings": {
        "versionId": ""
      }
    },
    "endpoint": {
      "url": "/_fusion/_reactivesearch",
      "method": "",
      "headers": ""
    }
  },
  "exportSettings": {
    "exportAs": "embed",
    "credentials": "a03a1cb71321:75b6603d-9456-4a5a-af6b-a487b309eb61",
    "openAsPage": false,
    "type": "other"
  },
  "chartSettings": {
    "charts": []
  },
  "syncSettings": null,
  "authenticationSettings": {
    "enableAuth0": false,
    "enableProfilePage": true,
    "profileSettingsForm": {
      "viewData": true,
      "editData": true,
      "closeAccount": true,
      "editThemeSettings": true,
      "editSearchPreferences": true
    },
    "clientId": "fQ50eZkW3WlFoDEfHAPBxiOTYmzSXZC7"
  },
  "appbaseSettings": {
    "index": "good-books-ds",
    "credentials": "a03a1cb71321:75b6603d-9456-4a5a-af6b-a487b309eb61",
    "url": "http://localhost:8000"
  }
};

export default JSON.stringify(appbasePrefs);
