<template>
  <div>
    <reactive-base
      :app="index"
      :url="url"
      :credentials="credentials"
      :preferences="preferences"
      enableAppbase
      v-bind="componentProps"
    >
      <div v-if="isMobile && dynamicFacets.length">
        <a-affix
          :style="{
            position: 'fixed',
            bottom: 0,
            'z-index': 4,
            left: 0,
            width: '100%',
          }"
        >
          <a-button
            block
            type="primary"
            :css="mobileButtonStyles"
            size="large"
            :icon="toggleFilters ? 'menu' : 'filter'"
            @click="handleToggleFilter"
          >
            {{ toggleFilters ? "Show Results" : "Show Filters" }}
          </a-button>
        </a-affix>
      </div>

      <div :style="{ 'max-width': '90%', margin: 'auto' }">
        <div v-if="Object.keys(logoSettings).length && logoSettings.logoUrl">
          <img
            :src="`${logoSettings.logoUrl}/tr:w-${logoSettings.logoWidth * 2}`"
            alt="logo-url"
            :style="{
              width: `${logoSettings.logoWidth}px`,
              height: `50px`,
              float: `${logoSettings.logoAlignment}`,
              margin: '10px 0px',
            }"
          />
        </div>

        <Search
          :toggleFilters="toggleFilters"
          :searchSettings="searchSettings"
          :resultSettings="resultSettings"
          :pageSettings="pageSettings"
          :theme="theme"
        />

        <div :class="`${filterResultsLayout}`">
          <Filters
            :theme="theme"
            :isMobile="isMobile"
            :preferences="preferences"
            :toggleFilters="toggleFilters"
            :getFontFamily="getFontFamily"
            :componentSettings="componentSettings"
            :pageSettings="pageSettings"
          />

          <div>
            <div
              v-if="tabDataList"
              :style="{
                'border-bottom': '1px solid lightgray',
                'margin-bottom': 20,
              }"
            ></div>

            <div
              v-if="
                globalSettings.showSelectedFilters &&
                !toggleFilters &&
                themeType !== 'minimal'
              "
              :css="viewSwitcherStyles"
              :style="{ 'margin-bottom': 10 }"
            >
              <selected-filters showClearAll />
            </div>

            <results-layout-by-category
              :preferences="preferences"
              :toggleFilters="toggleFilters"
            />
          </div>
        </div>
      </div>
    </reactive-base>
  </div>
</template>
<script>
import { css } from "@emotion/css";
import get from "lodash.get";
import Search from "./Search.vue";
import { getSearchPreferences } from "../utils";
import { ResultsLayoutByCategory } from "@appbaseio/vue-enterprise-search-ui";
import Filters from "./Filters.vue";

const viewSwitcherStyles = css`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .icon-styles {
    padding: 5px;
    &: hover {
      cursor: pointer;
      color: #40a9ff;
    }
  }
`;

const filterResultsLayout = css`
  display: grid;
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const mobileButtonStyles = css`
  border-radius: 0;
  border: 0;
`;

const preferences = getSearchPreferences();
const theme = get(preferences, "themeSettings.rsConfig", {});
// const themeSettings = get(preferences, "themeSettings");
const globalSettings = get(preferences, "globalSettings", {});
const themeType = get(preferences, "themeSettings.type");
const index = get(preferences, "appbaseSettings.index");
const credentials = get(preferences, "appbaseSettings.credentials");
const url = get(preferences, "appbaseSettings.url");
const userId = get(preferences, "appbaseSettings.userId", "");
const pageSettings = get(preferences, "pageSettings", {});
const componentSettings = get(
  pageSettings,
  `pages.${pageSettings.currentPage}.componentSettings`,
  {}
);
const logoSettings = get(globalSettings, "meta.branding", {});
const backend = get(preferences, "backend", "");
const isFusion = backend === "fusion";
const globalEndpoint = get(globalSettings, "endpoint");
const pageEndpoint = get(
  pageSettings,
  `pages.${pageSettings.currentPage}.indexSettings.endpoint`
);
const endpoint = { ...pageEndpoint, ...globalEndpoint };
const globalFusionSettings = get(preferences, "fusionSettings", {});
const pageFusionSettings = get(
  pageSettings,
  `pages.${pageSettings.currentPage}.indexSettings.fusionSettings`
);
const fusionSettings = {
  ...globalFusionSettings,
  ...pageFusionSettings,
};
const resultSettings = get(
  componentSettings,
  "result",
  get(preferences, "resultSettings", {})
);
const searchSettings = get(
  componentSettings,
  "search",
  get(preferences, "searchSettings", {})
);
const sortOptionSelector = get(resultSettings, "sortOptionSelector", []);
let newProps = {};
if (sortOptionSelector && sortOptionSelector.length) {
  newProps = {
    sortOptions: sortOptionSelector,
  };
}
const dynamicFacets = Object.keys(componentSettings).filter(
  (i) => i !== "search" && i !== "result"
);
const tabDataList = (Object.values(componentSettings).filter(
  (component) => component.componentType === "TABDATALIST"
) ?? [])[0];

export default {
  name: "SearchPlugin",
  components: {
    Search,
    "results-layout-by-category": ResultsLayoutByCategory,
    Filters,
  },
  data() {
    return {
      preferences,
      index,
      url,
      credentials,
      theme,
      userId,
      themeType,
      toggleFilters: false,
      isMobile: window.innerWidth <= 768,
      value: "",
      scrollContainer: null,
      pageSettings,
      tabDataList,
      dynamicFacets,
      mobileButtonStyles,
      logoSettings,
      globalSettings,
      endpoint,
      viewSwitcherStyles,
      newProps,
      searchSettings,
      resultSettings,
      componentSettings,
      filterResultsLayout,
      componentProps: {
        transformRequest: isFusion
          ? (props) => this.transformRequest(props)
          : undefined,
      },
    };
  },
  methods: {
    updateDimensions() {
      this.isMobile = window.innerWidth <= 768;
      this.toggleFilters = false;
    },
    scrollHandler() {
      const { scrollTop, clientHeight, scrollHeight } = this.scrollContainer;

      if (scrollTop + clientHeight >= scrollHeight) {
        // if (resultRef.current) {
        //   resultRef.current.loadMore();
        // }
      }
    },
    handleToggleFilter() {
      this.toggleFilters = !this.toggleFilters;
    },
    getFontFamily() {
      const receivedFont = get(theme, "typography.fontFamily", "");
      let fontFamily = "";
      if (receivedFont && receivedFont !== "default") {
        fontFamily = receivedFont; // eslint-disable-line
      }
      return fontFamily ? { fontFamily } : {};
    },
    transformRequest(props) {
      if (Object.keys(fusionSettings).length) {
        const newBody = JSON.parse(props.body);
        newBody.metadata = {
          app: fusionSettings.app,
          profile: fusionSettings.profile,
          suggestion_profile: fusionSettings.searchProfile,
          sponsored_profile: get(fusionSettings, "meta.sponsoredProfile", ""),
        };
        // eslint-disable-next-line
        props.body = JSON.stringify(newBody);
      }
      return props;
    },
  },
  mounted() {
    window.addEventListener("resize", this.updateDimensions);
    try {
      if (get(this.resultSettings, "rsConfig.infiniteScroll", {})) {
        const containerCollection =
          document.getElementsByClassName("ant-modal");

        if (containerCollection && containerCollection.length) {
          // eslint-disable-next-line
          this.scrollContainer = containerCollection[0];
          this.scrollContainer.addEventListener("scroll", this.scrollHandler);
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.updateDimensions);
  },
};
</script>
