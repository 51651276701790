<template>
  <div
    :key="filter"
    :style="{
      ...getFontFamily(),
      'max-width': isMobile ? 'none' : '298px',
    }"
    class="filter"
    v-if="facet && facet.enabled"
  >
    <div
      :style="{
        color: get(theme, 'colors.textColor'),
      }"
    >
      <div v-if="get(facet, 'rsConfig.componentType') === 'TABDATALIST'"></div>
      <div
        v-else-if="get(facet, 'rsConfig.componentType') === 'REACTIVECHART'"
      ></div>
      <div v-else-if="type === 'list'">
        <reactive-component
          :componentId="filter"
          :preferencesPath="`pageSettings.pages.${pageSettings.currentPage}.componentSettings.${filter}`"
          :style="{ ...getFontFamily() }"
          URLParams
          :filterLabel="get(facet, 'rsConfig.title', '')"
          title=""
          :react="{
            and: getReactDependenciesFromPreferences(preferences, filter),
          }"
          v-bind="facet.rsConfig"
        />
      </div>
      <div v-else-if="type === 'range' || type === 'date'">
        <div
          v-if="
            get(facet, 'rsConfig.startValue') && get(facet, 'rsConfig.endValue')
          "
        >
          <reactive-component
            :componentId="filter"
            :preferencesPath="`pageSettings.pages.${pageSettings.currentPage}.componentSettings.${filter}`"
            :style="{ ...getFontFamily() }"
            URLParams
            :filterLabel="get(facet, 'rsConfig.title', '')"
            :range="{
              start:
                type === 'date'
                  ? new Date(get(facet, 'rsConfig.startValue', ''))
                  : parseInt(get(facet, 'rsConfig.startValue', ''), 10),
              end:
                type === 'date'
                  ? new Date(get(facet, 'rsConfig.endValue', ''))
                  : parseInt(get(facet, 'rsConfig.endValue', ''), 10),
            }"
            :rangeLabels="{
              start: get(facet, 'rsConfig.startLabel', ''),
              end: get(facet, 'rsConfig.endLabel', ''),
            }"
            title=""
            :v-bind="{ ...dateProps, ...facet.rsConfig }"
          />
        </div>
        <div v-else>
          <reactive-component
            :componentId="filter"
            :preferencesPath="`pageSettings.pages.${pageSettings.currentPage}.componentSettings.${filter}`"
            :style="{ ...getFontFamily() }"
            URLParams
            :filterLabel="get(facet, 'rsConfig.title', '')"
            title=""
            :v-bind="dateProps"
          />
        </div>
      </div>
      <div v-else></div>
    </div>
  </div>
  <!-- </a-collapse-panel> -->
</template>
<script>
import get from "lodash.get";
import {
  getCalendarIntervalErrorMessage,
  getReactDependenciesFromPreferences,
} from "../utils";

export default {
  name: "Facet",
  data() {
    const type = get(this.facet, "rsConfig.filterType", "");
    let dateProps = {};
    if (this.facet && this.facet.rsConfig) {
      delete this.facet.rsConfig?.defaultQuery;
      delete this.facet.rsConfig?.setOption;
    }
    if (type === "date") {
      const calendarInterval = get(
        this.facet,
        "rsConfig.calendarInterval",
        "year"
      );
      dateProps = {
        queryFormat: "date",
        // eslint-disable-next-line
        calendarInterval: calendarInterval
          ? calendarInterval
          : getCalendarIntervalErrorMessage(
              new Date(get(this.facet, "rsConfig.startValue", "")) -
                new Date(get(this.facet, "rsConfig.endValue", ""))
            ).calculatedCalendarInterval,
      };
    }
    return {
      dateProps,
      get,
      type,
    };
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
    getFontFamily: {
      type: Function,
    },
    facet: {
      type: Object,
      default() {
        return {};
      },
    },
    theme: {
      type: Object,
      default() {
        return {};
      },
    },
    filter: {
      type: String,
      default: "",
    },
    pageSettings: {
      type: Object,
      default() {
        return {};
      },
    },
    preferences: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    getReactDependenciesFromPreferences,
  },
};
</script>
