import get from "lodash.get";
import appbasePrefs from "./constants";
import defaultTemplatePreferences from "./reactivesearchPreferences.json";

export const defaultPreferences = {
  themeSettings: {
    type: "classic",
    customCss: "",
    rsConfig: {},
  },
  searchSettings: {
    searchButton: {
      icon: "",
      text: "Click here to Search",
    },
    rsConfig: null,
    redirectUrlText: "View Product",
    redirectUrlIcon: "",
  },
  resultSettings: {
    rsConfig: {
      infiniteScroll: true,
    },
    layout: "grid",
    viewSwitcher: true,
    mapLayout: "map",
    locationDatafield: "location",
    mapComponent: "googleMap",
    defaultZoom: 13,
    showSearchAsMove: true,
    showMarkerClusters: true,
    mapsAPIkey: "",
    resultHighlight: false,
  },
  facetSettings: {
    globalSettings: {
      isCollapsible: true,
    },
    staticFacets: [],
    dynamicFacets: [],
  },
  globalSettings: {
    currency: "$",
    showSelectedFilters: true,
  },
  productRecommendationSettings: {
    title: "You might also like",
    rsConfig: {},
  },
  appbaseSettings: null,
  exportType: "other",
};

export const getReactDependenciesFromPreferences = (
  preferences = {},
  id = ""
) => {
  if (preferences.pageSettings) {
    const componentSettings = get(
      preferences.pageSettings,
      `pages.${preferences.pageSettings.currentPage}.componentSettings`,
      {}
    );
    return Object.keys(componentSettings).filter((i) => i !== id);
  }
  return [];
};

export const getSearchPreferences = () => {
  if (window.APPBASE_SEARCH_PREFERENCES || appbasePrefs) {
    try {
      const prefs = JSON.parse(
        window.APPBASE_SEARCH_PREFERENCES || appbasePrefs
      );
      if (typeof prefs === "string") {
        // eslint-disable-next-line no-use-before-define
        return transformPreferences(defaultTemplatePreferences);
      }

      // eslint-disable-next-line no-use-before-define
      return transformPreferences(prefs);
    } catch (e) {
      console.warn(
        "Appbase: Error encountered while parsing the search preferences, fall-backing to the default preferences"
      );
      // eslint-disable-next-line no-use-before-define
      return transformPreferences(defaultTemplatePreferences);
    }
  }
  // eslint-disable-next-line no-use-before-define
  return transformPreferences(defaultTemplatePreferences);
};

function parseJSON(str) {
  let parsedObj;
  try {
    if (typeof str === "string") parsedObj = JSON.parse(str);
    else parsedObj = str;
  } catch (e) {
    // Silence error
  }
  return parsedObj;
}

function normalizePreferences(preferences) {
  const clonePreferences = { ...preferences };
  Object.keys(clonePreferences.pageSettings.pages).forEach((page) => {
    const pagePreferences = clonePreferences.pageSettings.pages[page] || {};
    const { componentSettings } = pagePreferences || {};
    const { result: resultComponent, search: searchComponent } =
      componentSettings || {};
    if (resultComponent) {
      const hasOldStructure = typeof resultComponent.fields.title === "string";
      if (hasOldStructure) {
        Object.keys(resultComponent.fields)
          .filter((field) => field !== "userDefinedFields")
          .forEach((field) => {
            if (resultComponent.fields[field]) {
              resultComponent.fields[field] = {
                dataField: resultComponent.fields[field],
                highlight: false,
              };
            }
          });
        if (resultComponent.displayFields) {
          Object.keys(resultComponent.displayFields).forEach((category) => {
            const categoryFields = resultComponent.displayFields[category];
            Object.keys(categoryFields).forEach((field) => {
              if (categoryFields[field]) {
                categoryFields[field] = {
                  dataField: categoryFields[field],
                  highlight: false,
                };
              }
            });
          });
        }
      }
    }
    if (searchComponent) {
      const hasOldStructure = typeof searchComponent.fields.title === "string";
      if (hasOldStructure) {
        Object.keys(searchComponent.fields)
          .filter((field) => field !== "userDefinedFields")
          .forEach((field) => {
            if (searchComponent.fields[field]) {
              searchComponent.fields[field] = {
                dataField: searchComponent.fields[field],
                highlight: false,
              };
            }
          });
        if (searchComponent.displayFields) {
          Object.keys(searchComponent.displayFields).forEach((category) => {
            const categoryFields = searchComponent.displayFields[category];
            Object.keys(categoryFields).forEach((field) => {
              if (categoryFields[field]) {
                categoryFields[field] = {
                  dataField: categoryFields[field],
                  highlight: false,
                };
              }
            });
          });
        }
      }
    }
  });
  return clonePreferences;
}

function transformPreferences(preferences) {
  const normalizedPreferences = normalizePreferences(preferences);
  if (
    normalizedPreferences.globalSettings &&
    normalizedPreferences.globalSettings.endpoint
  ) {
    const { endpoint } = normalizedPreferences.globalSettings;
    const { appbaseSettings } = normalizedPreferences;
    // We may get a url relative to cluster
    const isRelative = endpoint.url[0] === "/";

    normalizedPreferences.globalSettings.endpoint = {
      url: isRelative ? appbaseSettings.url + endpoint.url : endpoint.url,
      headers: parseJSON(endpoint.headers),
      method: endpoint.method,
    };
  }
  if (normalizedPreferences.pageSettings) {
    Object.keys(normalizedPreferences.pageSettings.pages).forEach((page) => {
      const pagePreferences =
        normalizedPreferences.pageSettings.pages[page] || {};
      const { componentSettings } = pagePreferences || {};
      const { result: resultComponent, search: searchComponent } =
        componentSettings || {};
      let highlightConfig = { fields: {} };

      if (
        pagePreferences.indexSettings &&
        pagePreferences.indexSettings.endpoint
      ) {
        const { endpoint } = pagePreferences.indexSettings;
        const { appbaseSettings } = normalizedPreferences;
        // We may get a url relative to cluster
        const isRelative = endpoint.url[0] === "/";

        pagePreferences.indexSettings.endpoint = {
          url: isRelative ? appbaseSettings.url + endpoint.url : endpoint.url,
          headers: parseJSON(endpoint.headers),
          method: endpoint.method,
        };
      }
      if (resultComponent) {
        Object.keys(resultComponent.fields.userDefinedFields || {}).forEach(
          (field) => {
            if (
              resultComponent.fields.userDefinedFields[field] &&
              resultComponent.fields.userDefinedFields[field].highlight &&
              resultComponent.fields.userDefinedFields[field].dataField
            ) {
              const { dataField } =
                resultComponent.fields.userDefinedFields[field];
              highlightConfig.fields[dataField] = {};
            }
          }
        );
        Object.keys(resultComponent.fields)
          .filter((field) => field !== "userDefinedFields")
          .forEach((field) => {
            if (
              resultComponent.fields[field] &&
              resultComponent.fields[field].highlight &&
              resultComponent.fields[field].dataField
            ) {
              const { dataField } = resultComponent.fields[field];
              highlightConfig.fields[dataField] = {};
            }
          });
        Object.keys(resultComponent.displayFields || {}).forEach((category) => {
          const categoryFields = resultComponent.displayFields[category];
          Object.keys(categoryFields).forEach((field) => {
            if (
              categoryFields[field] &&
              categoryFields[field].highlight &&
              categoryFields[field].dataField
            ) {
              const { dataField } = categoryFields[field];
              highlightConfig.fields[dataField] = {};
            }
          });
        });
        highlightConfig = Object.keys(highlightConfig.fields).length
          ? highlightConfig
          : undefined;
      }

      if (resultComponent) {
        resultComponent.rsConfig.highlightConfig =
          resultComponent.resultHighlight ? highlightConfig : undefined;
      }
      if (searchComponent) {
        searchComponent.rsConfig.highlight =
          componentSettings.result.resultHighlight;
        searchComponent.rsConfig.highlightConfig =
          resultComponent.resultHighlight ? highlightConfig : undefined;
      }
    });
  }

  return normalizedPreferences;
}

export const getDataFieldValue = (value) => {
  if (value && typeof value === "object") {
    return value.dataField;
  }
  return value;
};

const queryFormatMillisecondsMap = {
  // the below are arranged in asscending order
  // please maintain the order if adding/ removing property(s)
  minute: 60000,
  hour: 3600000,
  day: 86400000,
  week: 604800000,
  month: 2629746000,
  quarter: 7889238000,
  year: 31556952000,
};

export const getCalendarIntervalErrorMessage = (
  totalRange,
  calendarInterval = "minute"
) => {
  const queryFormatMillisecondsMapKeys = Object.keys(
    queryFormatMillisecondsMap
  );
  const indexOfCurrentCalendarInterval =
    queryFormatMillisecondsMapKeys.indexOf(calendarInterval);
  if (indexOfCurrentCalendarInterval === -1) {
    console.error("Invalid calendarInterval Passed");
  }

  if (calendarInterval === "year") {
    return "Try using a shorter range of values.";
  }

  for (
    let index = indexOfCurrentCalendarInterval + 1;
    index < queryFormatMillisecondsMapKeys.length;
    index += 1
  ) {
    if (totalRange / Object.values(queryFormatMillisecondsMap)[index] <= 100) {
      const calendarIntervalKey = queryFormatMillisecondsMapKeys[index];
      return {
        errorMessage: `Please pass calendarInterval prop with value greater than or equal to a \`${calendarIntervalKey}\` for a meaningful resolution of histogram.`,
        calculatedCalendarInterval: calendarIntervalKey,
      };
    }
  }

  return {
    errorMessage: "Try using a shorter range of values.",
    calculatedCalendarInterval: "year",
  }; // we return the highest possible interval to shorten then interval value
};
