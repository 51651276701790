var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.facetStyles(_vm.toggleFilters)},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter._id},[_c('a-collapse',{attrs:{"bordered":false,"activeKey":[filter]}},[(_vm.get(_vm.componentSettings[filter], 'enabled'))?_c('a-collapse-panel',{key:filter,staticClass:"filter",style:({
          ..._vm.getFontFamily(),
          'max-width': _vm.isMobile ? 'none' : '298px',
          overflow: 'auto',
        }),attrs:{"showArrow":""},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('span',{style:({
              color: _vm.get(_vm.theme, 'colors.titleColor'),
              'font-weight': 'bold',
              'font-size': '15px',
              display: 'flex'
            })},[_vm._v(" "+_vm._s(_vm.get(_vm.componentSettings[filter], "rsConfig.title"))+" ")])]},proxy:true}],null,true)},[_c('Facet',{attrs:{"facet":_vm.componentSettings[filter],"getFontFamily":_vm.getFontFamily,"theme":_vm.theme,"filter":filter,"pageSettings":_vm.pageSettings,"preferences":_vm.preferences}})],1):_vm._e()],1)],1)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }