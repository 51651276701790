var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('reactive-base',_vm._b({attrs:{"app":_vm.index,"url":_vm.url,"credentials":_vm.credentials,"preferences":_vm.preferences,"enableAppbase":""}},'reactive-base',_vm.componentProps,false),[(_vm.isMobile && _vm.dynamicFacets.length)?_c('div',[_c('a-affix',{style:({
          position: 'fixed',
          bottom: 0,
          'z-index': 4,
          left: 0,
          width: '100%',
        })},[_c('a-button',{attrs:{"block":"","type":"primary","css":_vm.mobileButtonStyles,"size":"large","icon":_vm.toggleFilters ? 'menu' : 'filter'},on:{"click":_vm.handleToggleFilter}},[_vm._v(" "+_vm._s(_vm.toggleFilters ? "Show Results" : "Show Filters")+" ")])],1)],1):_vm._e(),_c('div',{style:({ 'max-width': '90%', margin: 'auto' })},[(Object.keys(_vm.logoSettings).length && _vm.logoSettings.logoUrl)?_c('div',[_c('img',{style:({
            width: `${_vm.logoSettings.logoWidth}px`,
            height: `50px`,
            float: `${_vm.logoSettings.logoAlignment}`,
            margin: '10px 0px',
          }),attrs:{"src":`${_vm.logoSettings.logoUrl}/tr:w-${_vm.logoSettings.logoWidth * 2}`,"alt":"logo-url"}})]):_vm._e(),_c('Search',{attrs:{"toggleFilters":_vm.toggleFilters,"searchSettings":_vm.searchSettings,"resultSettings":_vm.resultSettings,"pageSettings":_vm.pageSettings,"theme":_vm.theme}}),_c('div',{class:`${_vm.filterResultsLayout}`},[_c('Filters',{attrs:{"theme":_vm.theme,"isMobile":_vm.isMobile,"preferences":_vm.preferences,"toggleFilters":_vm.toggleFilters,"getFontFamily":_vm.getFontFamily,"componentSettings":_vm.componentSettings,"pageSettings":_vm.pageSettings}}),_c('div',[(_vm.tabDataList)?_c('div',{style:({
              'border-bottom': '1px solid lightgray',
              'margin-bottom': 20,
            })}):_vm._e(),(
              _vm.globalSettings.showSelectedFilters &&
              !_vm.toggleFilters &&
              _vm.themeType !== 'minimal'
            )?_c('div',{style:({ 'margin-bottom': 10 }),attrs:{"css":_vm.viewSwitcherStyles}},[_c('selected-filters',{attrs:{"showClearAll":""}})],1):_vm._e(),_c('results-layout-by-category',{attrs:{"preferences":_vm.preferences,"toggleFilters":_vm.toggleFilters}})],1)],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }