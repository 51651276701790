var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:({
    'padding': '25px 0px',
    position: _vm.showSearchAs,
    top: '10px',
    'z-index': 1000,
    display: _vm.toggleFilters ? 'none' : 'block',
  })},[_c('reactive-component',_vm._b({staticClass:"search",attrs:{"preferencesPath":`pageSettings.pages.${_vm.pageSettings.currentPage}.componentSettings.search`,"componentId":"search","filterLabel":"Search","debounce":100,"placeholder":_vm.searchText,"iconPosition":"right","icon":_vm.searchIcon,"URLParams":"","css":_vm.searchStyles(_vm.theme.colors),"popularSuggestionsConfig":{
      size: 3,
    },"recentSuggestionsConfig":{
      size: 3,
    },"enableIndexSuggestions":"","indexSuggestionsConfig":{
      size: 3,
      valueFields: _vm.valueFields,
    },"size":6,"showDistinctSuggestions":""}},'reactive-component',_vm.componentProps,false))],1)
}
var staticRenderFns = []

export { render, staticRenderFns }