var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.facet && _vm.facet.enabled)?_c('div',{key:_vm.filter,staticClass:"filter",style:({
    ..._vm.getFontFamily(),
    'max-width': _vm.isMobile ? 'none' : '298px',
  })},[_c('div',{style:({
      color: _vm.get(_vm.theme, 'colors.textColor'),
    })},[(_vm.get(_vm.facet, 'rsConfig.componentType') === 'TABDATALIST')?_c('div'):(_vm.get(_vm.facet, 'rsConfig.componentType') === 'REACTIVECHART')?_c('div'):(_vm.type === 'list')?_c('div',[_c('reactive-component',_vm._b({style:({ ..._vm.getFontFamily() }),attrs:{"componentId":_vm.filter,"preferencesPath":`pageSettings.pages.${_vm.pageSettings.currentPage}.componentSettings.${_vm.filter}`,"URLParams":"","filterLabel":_vm.get(_vm.facet, 'rsConfig.title', ''),"title":"","react":{
          and: _vm.getReactDependenciesFromPreferences(_vm.preferences, _vm.filter),
        }}},'reactive-component',_vm.facet.rsConfig,false))],1):(_vm.type === 'range' || _vm.type === 'date')?_c('div',[(
          _vm.get(_vm.facet, 'rsConfig.startValue') && _vm.get(_vm.facet, 'rsConfig.endValue')
        )?_c('div',[_c('reactive-component',{style:({ ..._vm.getFontFamily() }),attrs:{"componentId":_vm.filter,"preferencesPath":`pageSettings.pages.${_vm.pageSettings.currentPage}.componentSettings.${_vm.filter}`,"URLParams":"","filterLabel":_vm.get(_vm.facet, 'rsConfig.title', ''),"range":{
            start:
              _vm.type === 'date'
                ? new Date(_vm.get(_vm.facet, 'rsConfig.startValue', ''))
                : parseInt(_vm.get(_vm.facet, 'rsConfig.startValue', ''), 10),
            end:
              _vm.type === 'date'
                ? new Date(_vm.get(_vm.facet, 'rsConfig.endValue', ''))
                : parseInt(_vm.get(_vm.facet, 'rsConfig.endValue', ''), 10),
          },"rangeLabels":{
            start: _vm.get(_vm.facet, 'rsConfig.startLabel', ''),
            end: _vm.get(_vm.facet, 'rsConfig.endLabel', ''),
          },"title":"","v-bind":{ ..._vm.dateProps, ..._vm.facet.rsConfig }}})],1):_c('div',[_c('reactive-component',{style:({ ..._vm.getFontFamily() }),attrs:{"componentId":_vm.filter,"preferencesPath":`pageSettings.pages.${_vm.pageSettings.currentPage}.componentSettings.${_vm.filter}`,"URLParams":"","filterLabel":_vm.get(_vm.facet, 'rsConfig.title', ''),"title":"","v-bind":_vm.dateProps}})],1)]):_c('div')])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }